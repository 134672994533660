import React from 'react'

function Header() {
  return (
     <div className = "Header-image-div-container">
        <h2 className = "Header-image-div-text">Catering & Oyster Shucking</h2>
    </div>
  )
}

export default React.memo(Header);