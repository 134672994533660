import React from 'react'
import {scrollTo} from '../../helpers'

export default function WebNav() {
  return (
    <>
    <ul className = "Nav-list-parent_web">
        <li className = "Nav-list-item" onClick = {scrollTo}>About</li>
        <li className = "Nav-list-item" onClick = {scrollTo}>Gallery</li>
        <li className = "Nav-list-item" onClick = {scrollTo}>Contact</li>
    </ul>
    </>
  )
}
