import React from 'react'
import instagram from '../../media/icons/instagram.png'

export default function Contact() {
  const openInsta = () => {
    window.open("https://www.instagram.com/shuckologist/?igshid=NmZiMzY2Mjc=")
  }
  return (
    <div className="Contact-main">
      <h2>Contact Info</h2>
      <div className="Contact-nest-div">
        <h4>
          <span
            className="Contact-email"
            onClick={() =>
              (window.location = "mailto:shuckologist@outlook.com")
            }>Email -  shuckologist@outlook.com
          </span>
        </h4>
        <h4>
          <a className="Contact-number" href="tel:4015722032">
          Phone -  (401)572-2032
          </a>
        </h4>
      </div>
      <h3 className = "Contact-social-title">Social Media</h3>
      <div className="Contact-nest-div-social-media">
        <img
          src={instagram}
          onClick={openInsta}
          className="Contact-icon"
          alt="Instagram icon"
        ></img>
      </div>
    </div>
  );
}
