import React, {useState, useEffect} from 'react'
import Varieties from './Varieties'
import chrisWorking  from '../../media/pictures/chris-working.jpg'
import chrisWorking2 from "../../media/pictures/chris-working2.jpg"
import chrisWorking3 from '../../media/pictures/chris-working3.jpg'
import chrisWorking4 from "../../media/pictures/chris-working4.jpg"

function About() {
  const [mobile, setMobile] = useState(
    window.innerWidth<800 ? true : false
  );

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 800) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    });
  }, []);

  return (
    <>
      <div id="About"></div>
      <div className="About-paragraph-div">
        <h2>About</h2>
        <p className="About-paragraph">
          <div className="About-div">
            I started working on an oyster farm in 2017 shortly after graduating
            from the University of Rhode Island, where I studied aquaculture and
            fisheries technology.
            <img
              className="About-image"
              src={chrisWorking}
              alt="Chris mid shuck."
            />
          </div>
          {mobile ? (
            <div className="About-div about-two">
              I spend my mornings farming oysters on West Passage Oyster Farm,
              and my evenings shucking them at Providence Oyster Bar. Over these
              past few years, I've become very well acquainted with the entire
              oyster process, farm to table.
              <img
                className="About-image"
                src={chrisWorking3}
                alt="Chris serving customers oysters."
              />
            </div>
          ) : (
            <div className="About-div about-two">
              <img
                className="About-image"
                src={chrisWorking3}
                alt="Chris serving customers oysters."
              />
              I spend my mornings farming oysters on West Passage Oyster Farm,
              and my evenings shucking them at Providence Oyster Bar. Over these
              past few years, I've become very well acquainted with the entire
              oyster process, farm to table.
            </div>
          )}
          <div className="About-div">
            Now I want to share my passion for shucking with the world! If you
            hire me for an event I will provide the freshest oysters shucked to
            perfection with cocktail, minginette and lemons for everyone's
            enjoyment.
            <img
              className="About-image"
              src={chrisWorking4}
              alt="Chris shucking an oyster."
            />
          </div>
          {mobile ? (
            <p className="About-sentence">Oysters are my life, let me show you why!</p>
          ) : (
            <span>Oysters are my life, let me show you why!</span>
          )}
        </p>
        {/* <Varieties /> */}
       <div className="About-section-fact">
          <h2>Let me cater your event!</h2>
        </div>
        <div className="About-section-fact">
          <img src={chrisWorking2} alt="Chris preparing oysters." />
        </div>
      </div>
    </>
  );
}

export default React.memo(About)