import React from 'react'
import Header from'./Header'
import About from './about'

const Gallery = React.lazy(()=> import ('./gallery'));

export default function Main() {
  return (
  <>
  <div className = "Main">
      <Header/>
      <About/>
      <Gallery />
  </div>
  </>
  )
}