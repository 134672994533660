import React from 'react'
import emailjs from '@emailjs/browser';
import { scrollUp } from '../../../helpers';

const Form = () => {

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_ige3ih7', 'shuckologist_website', e.target, 'JEFPeWtusIOu_yuTZ')
      .then(() => {
          scrollUp();
          alert (`Thanks for the message. We'll be in touch!`)
        }, (error) => {
          scrollUp();
          alert(`Can't handle message at the moment. Sorry about that.`)
          console.log(error.text);
      });
      e.target.reset()
    };

  return (
    <form className = "Form-main" onSubmit = {sendEmail}>
     
     <div className = "Form-div">
        <div className = "Form-nest-div">
          <label 
            htmlFor = "form" 
            className = "Form-label">
            First Name <span className = "required"> * </span> 
          </label>
          <input 
            type = "text" 
            placeholder = "First Name" 
            className = "Form-input" 
            name = "First Name"
            required/>
        </div>
      
        <div className = "Form-nest-div">
          <label 
            htmlFor = "form" 
            className = "Form-label">
            Last Name
          </label>
          <input 
            type = "text" 
            placeholder = "Last Name" 
            className = "Form-input" 
            name = "Last Name"/>
        </div>
      
        <div className = "Form-nest-div">
          <label 
            htmlFor = "form" 
            className = "Form-label">
            Email <span className = "required"> * </span> 
          </label>
          <input 
            type = "text" 
            placeholder = "Email" 
            className = "Form-input" 
            name = "Email"
            required/>
        </div>
      
        <div className = "Form-nest-div">
          <label 
            htmlFor = "form" 
            className = "Form-label">
            Phone
          </label>
          <input 
            type = "text" 
            placeholder = "Phone Number" 
            className = "Form-input" 
            name = "Phone"/>
        </div>
      </div>
      
      <div className = "Form-message-div">
          <label htmlFor = "textarea">Message <span className = "required">*</span></label>
          <textarea 
            type = "text" 
            className = "Form-message" 
            rows = "12" 
            name = "Message"
            required/>
      </div>

      <div className = "Form-submit-div">
          <button 
            type = "submit" 
            className = "Form-submit">
            Submit
          </button>
      </div>
    </form>
  )
}

export default React.memo(Form)