import React, {useState} from 'react'
import SideMenu from './SideMenu'
import closedOy  from '../../media/icons/closed-oy.png'
import openOy  from '../../media/icons/open-oy.png'

export default function MobileNav({setShowMenu, showMenu, menu, setMenu}) {

  const [icon, setIcon] = useState(false);
  const toggleMenu = () => {
    setIcon(!icon)
    setMenu(!menu)
    setShowMenu(!showMenu);
  }

  return (
  
  <ul className = "Nav-list-parent_mobile">
    {icon 
      ? 
      <img 
        className = "Nav-icon open" 
        src = {openOy}
        onClick = {toggleMenu}></img>
      :
      <img 
        className = "Nav-icon closed" 
        src = {closedOy}
        onClick = {toggleMenu}></img>
      }
      <SideMenu 
        menu = {menu}
        toggleMenu = {toggleMenu}/>
    </ul>
  )
}
