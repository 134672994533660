import React from 'react'
import Form from './form'
import Contact from'./Contact'
import Copyright from './Copyright'

function Footer() {
  return (
    <>
    <div id = "Contact"></div>
    <div className = "Footer-main">
      <h2 className = "Footer-header">Let's talk catering</h2>
      <Form />
      <Contact />
      <Copyright/>
    </div>
    </>
  )
}

export default React.memo(Footer)
