import './styles/index.css'
import Nav from './components/nav'
import Main from './components/Main'
import Footer from './components/footer/index'
import React, {useState, useEffect} from 'react'

function App() {
  const [showMenu, setShowMenu] = useState(false);

  useEffect(()=> {
    if(showMenu === true){
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'scroll'
    }
  },[showMenu])

return (
  <>
  <div className="App">
    <Nav 
      setShowMenu = {setShowMenu} 
      showMenu = {showMenu}></Nav>
      
      <div 
        className = {showMenu === true 
          ? "menu-open Body-container" 
          : "Body-container"}
        >
        <Main></Main>
        <Footer></Footer>
      </div>
    </div>
  </>
  );
}

export default App;
