import React from 'react'
import {scrollTo} from '../../helpers'

export default function SideMenu({menu, toggleMenu}) {
  
  const handleScroll = (e) => {
    scrollTo(e);
    return toggleMenu();
  }

  const handleClick = (e) =>{
    if (menu === false){
      return;
    } else {
      if(menu === true){
        if(e.target.classList.contains('Nav-icon')){
          return;
         } else {
          if(e.target.classList.contains('handle')===false)
            return toggleMenu();
         }
       }
    }
  }
  
  document.addEventListener('mousedown',handleClick);
  
  return (
    <div>
      {menu===false 
        ? 
        <div className = "hide"></div>
        :
        <div className = "SideMenu show handle">
          <div className = "SideMenu-close handle">
            <p 
              className = "SideMenu-close-button"
              onClick = {toggleMenu}>X</p>
          </div>
          <ul className = "SideMenu-list handle">
            <li 
              className = "SideMenu-item handle" 
              onClick = {handleScroll}
              >About
            </li>
            <li 
              className = "SideMenu-item handle"
              onClick = {handleScroll}
              >Gallery
            </li>
            <li 
              className = "SideMenu-item handle"
              onClick = {handleScroll}
              >Contact
            </li>
          </ul>
        </div>
      }
    </div>
  )
}
