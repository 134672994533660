import React, {useState, useEffect} from 'react'
import MobileNav from './MobileNav'
import WebNav from './WebNav'

export default function Nav({setShowMenu, showMenu}) {

  const[menu,setMenu] = useState(false);
  const [mobile, setMobile] = useState(
    window.innerWidth<1000 ? true : false
  );

  useEffect(()=>{
    window.addEventListener('resize',()=>{
      if(window.innerWidth<1000){
        setMobile(true)
      } else {
        setMobile(false);
      }
    })
  },[window.innerWidth])

   useEffect(()=>{
     setMenu(false);
     setShowMenu(false)
   },[mobile])
  
  return (
    <div className = "Nav-main">
      {mobile === true ? 
        <MobileNav 
          setShowMenu = {setShowMenu} 
          showMenu = {showMenu}
          menu = {menu} 
          setMenu = {setMenu}>
        </MobileNav>
        :
        <WebNav></WebNav>
      }
      <div className = "Nav-name">
        <h2 id ="name">shuckologist.</h2>
      </div>
    </div>
  )
}

