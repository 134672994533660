import React, {useState} from 'react'
import arrow from '../../media/icons/right-arrow.png'
import example from '../../media/pictures/oyster2.jpg'

function Varieties() {

  const [loading, setLoading] = useState(false);
  const [oyVars, setOyVars] = useState([
      {
      image:example,
      name:'Oyster One',
      description:'oyster number 1 description will go here.'
      },
    {
      image:example,
      name:'Oyster Two',
      description:'oyster number 2 description will go here.'
    },
    {
      image:example,
      name:'Oyster Three',
      description:'oyster number 3 description will go here.'
    },
  ]);

  const one = [
      {
      image:example,
      name:'Oyster One',
      description:'oyster number 1 description will go here.'
      },
    {
      image:example,
      name:'Oyster Two',
      description:'oyster number 2 description will go here.'
    },
    {
      image:example,
      name:'Oyster Three',
      description:'oyster number 3 description will go here.'
    },
  ];

  const two = [
     {
      image:example,
      name:'Oyster Four',
      description:'oyster number 4 description will go here.'
    },
    {
      image:example,
      name:'Oyster Five',
      description:'oyster number 5 description will go here.'
    },
    {
      image:example,
      name:'Oyster Six',
      description:'oyster number 6 description will go here.'
    },
  ]

  const changeList = () => {
    if (oyVars[0].name === 'Oyster Four'){
      setOyVars(one)
    } else if (oyVars[0].name === 'Oyster One') {
      setOyVars(two)
    } else {
      setOyVars(two);
    }
    setLoading(true);
    return handleFade();
  }

  const handleFade = () => {
    setTimeout(()=>{
    setLoading(false);
    },500)
    
  }

  const handleSwipe = (e) => {
    let start = e.pageX;
    console.log(start)  
  }

  return (
  <div className = "Varieties-main">
    <h2 className = "Varieties-title">Oysters we work with</h2>

    <div className = "Varieties-outer">
    
      <div className = "Varieties-arrow-div">
        <img 
          src = {arrow} 
          className = 
            {loading ? "Varieties-arrow flipped disable" : "Varieties-arrow flipped"}
          onClick = {changeList}>
        </img>
      </div>
      
      <div className = {loading ? "Animation-change Varieties-grid":"Varieties-grid"}>
      {oyVars.map((index)=>(
        <div className = "Varieties-child" onMouseDown = {handleSwipe}>
          <img src = {index.image} className = "Varieties-image"/>
          <h3>{index.name}</h3>
          <p className = "Varieties-child-p">{index.description}</p>
        </div>
      ))}
      </div>
    
       <div className = "Varieties-arrow-div">
        <img 
          src = {arrow} 
          className =
           {loading ? "Varieties-arrow disable" : "Varieties-arrow"}
          onClick = {changeList}></img>
      </div> 

    </div>
  </div>
  )
}

export default React.memo(Varieties);

